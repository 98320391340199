import Link from "next/link";
import { graphql } from "lib/gql";

import { Button } from "components/Button";
import { EyeUnfilled } from "components/Icons";
import { Image } from "lib/imgproxy";
import { useQuery } from "@apollo/client";
import { TFunction } from "i18next";
import { UrlObject } from "url";

declare type Url = string | UrlObject;
const searchCentreCardQuery = graphql(`
    query searchCentreCardQuery {
        categories {
            uid
            name
        }
    }
`);

type CentreCardProps = {
    t: TFunction<["components/SearchPage", "common"]>;
    name: string;
    cover?: string;
    cityAndState?: string;
    bookNowHref: Url;
    href: Url;
    categoryId?: string | null;
    categoryIds: string[];
    isAvailable?: boolean;
    loading?: boolean;
};

const CentreCard = ({
    t,
    name,
    cover,
    cityAndState,
    bookNowHref,
    href,
    categoryIds,
    isAvailable = true,
    loading = false,
}: CentreCardProps): JSX.Element => {
    const { data } = useQuery(searchCentreCardQuery);
    const dCategories = data?.categories ?? [];
    const categoryNames = categoryIds
        .map((id) => dCategories.find((c) => c.uid === id)?.name)
        .filter((name) => name !== undefined)
        .join(", ");

    let actions;

    if (loading) {
        actions = (
            <nav className="grid grid-cols-1">
                <Button className="tertiary" disabled>
                    {`${t("common:loading", "Loading")}...`}
                </Button>
            </nav>
        );
    } else if (isAvailable) {
        actions = (
            <nav className="grid grid-cols-2 gap-2">
                <Link href={href} passHref>
                    <Button variant="tertiary" asChild>
                        <a className="flex gap-2 no-underline">
                            <EyeUnfilled className="size-5" />
                            {t("common:buttonView", "View")}
                        </a>
                    </Button>
                </Link>
                <Link href={bookNowHref} passHref>
                    <Button variant="secondary" asChild>
                        <a className="flex gap-2 no-underline">
                            {t("common:buttonBookNow", "Book Now")}
                        </a>
                    </Button>
                </Link>
            </nav>
        );
    } else {
        actions = (
            <nav className="grid grid-cols-1">
                <Button className="tertiary" disabled>
                    {t("noSlots", "No slots available")}
                </Button>
            </nav>
        );
    }

    return (
        <li className="flex cursor-pointer flex-col gap-2 rounded-xl border-[0.5px] border-solid border-blue-grey-50 bg-white pb-4 transition-all lg:group-data-[filter=hide]:w-[332px] lg:group-data-[filter=show]:w-[320px]">
            <div className="relative aspect-video w-full">
                <Image
                    src={cover || "/images/default_centre.jpg"}
                    alt={`${name} Cover`}
                    layout="fill"
                    unoptimized={!cover}
                    objectFit="cover"
                    className="rounded-t-xl"
                    sizes="(max-width: 576px) 550px,(max-width: 768px) 750px, 400px"
                />
            </div>
            <section className="mx-4 flex flex-1 flex-col justify-between gap-5">
                <div>
                    <span className="typography-micro line-clamp-1 uppercase text-blue-grey">
                        {categoryNames}
                    </span>
                    <span className="typography-h4 line-clamp-1 font-bold text-blue-grey-900">
                        {name}
                    </span>
                    <span className="typography-tiny line-clamp-2 text-blue-grey-500">
                        {cityAndState}
                    </span>
                </div>
                {actions}
            </section>
        </li>
    );
};

export { CentreCard };
